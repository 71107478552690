body {
  /* font-family: 'Roboto Condensed', sans-serif !important; */
  font-family: 'Roboto Condensed', sans-serif;

  background-color: #f7f9fb !important;
}

/* * {
  font-family: 'Roboto Condensed', sans-serif;
} */

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600 !important;
}
.fw-400 {
  font-weight: 400;
}

.fs-12 {
  font-size: 12px;
}
.fs-13 {
  font-size: 13px;
}
.right-20 {
  right: 20px !important;
}
.right-15 {
  right: 15px !important;
}
.fs-14 {
  font-size: 14px;
}
.fs-16 {
  font-size: 16px;
}

.left-bar {
  /* width: 36.2% !important; */
  width: 500px;
  border: 0px !important;
}
/* 36.5% */
.main-wrapper {
  width: auto;
  padding: 0px !important;
  background: #f7f9fb;
  margin-top: 3.7rem;
  margin-right: -300px;
  /* margin-right: -293px; */
}

.main-wrapper.both-sidebar-active {
  margin-right: -7px;
  /* margin-right: 0px; */
}
.main-wrapper.right-header-sidebar-active {
  margin-right: 1px;
  /* margin-right: 0px; */
}

.main-wrapper.both-sidebar-active {
  margin-right: -1px;
  /* margin-right: -24px; */
}

/* main.resetRight {
  margin-right: -300px;
} */

.rightDrawer {
  width: 300px !important;
  /* width: 23% !important; */
  /* background-color: #fff; */
  border-bottom: 1px solid #eeeeee;
}

.gray-section-right-bar {
  background-color: #fff;
  padding-bottom: 5px;
  border-bottom: 1px solid #eeeeee;
}

.left-sidebar-new ul li {
  display: block;
  background-color: #ffffff4d;
  padding: 2px 0px 3px 9px;
  margin: 0 15px 0 15px;
  border-radius: 8px;
}
.add-icon {
  width: 18px;
  height: auto;
}
.left-sidebar-new ul {
  margin: 0;
  padding: 0;
}
.left-sidebar-new ul li span {
  color: #fff;
  padding-left: 8px;
  font-size: 13px;
}
.recentSongs h5 {
  color: #fff;
  font-size: 13px;
  padding: 10px 0px 0px 15px;
  font-weight: 300;
}
.song.side-menu-song-recent.active {
  background-color: #fff;
  display: block;
  padding: 9px;
  margin: 0 15px 0 15px;
  border-radius: 8px;
}
.recent-song-inactive {
  display: block;
  /* padding: 5px 0px 0px 22px; */
  padding: 4px 0px 4px 8px;
  margin: 0px 15px 0 15px;

  border-radius: 8px;
  font-size: 12px;
}
.recent-song-inactive span {
  color: #fff;
  padding-left: 8px;
}

.leftASideWrapper {
  max-width: 210px;
}

.leftASideWrapper .song span {
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  user-select: none;
}
.Add-note {
  display: block;
  background-color: #ffffff4d;
  padding: 2px 0px 3px 9px;
  margin: 0 15px 0 15px;
  border-radius: 8px;
}
.Add-note span {
  color: #fff;
  padding-left: 8px;
  font-size: 13px;
}
.line-white {
  height: 1px;
  background-color: #fff;
  margin: 10px 18px 10px 18px;
}
.addSong.notebook.cursor {
  padding: 15px 0 0 25px;
}
.addSong.notebook.cursor span {
  color: #fff;
}
.ai-assist.cursor {
  display: block;
  background-color: #ffffff4d;
  padding: 2px 0px 3px 9px;
  margin: 0 15px 0 15px;
  border-radius: 8px;
}
.ai-assist.cursor span {
  color: #fff;
  padding-left: 8px;
  font-size: 13px;
}
.AI-generated.cursor {
  padding: 6px 0 0px 22px;
}

.AI-generated.cursor img {
  width: 18px;
  height: auto;
}

.AI-generated.cursor span {
  color: #fff;
  padding-left: 8px;
  font-size: 13px;
}
.Folders {
  display: block;
  background-color: #ffffff4d;
  padding: 5px 0px 5px 9px;
  margin: 0 15px 0 15px;
  border-radius: 8px;
  font-size: 13px;
}
.Folders span {
  color: #fff;
  padding-left: 8px;
}
.folder_active:first-child,
.all_folder_active:first-child {
  display: block;
  background-color: #fff;
  padding: 2px 0px 3px 8px;
  margin: 5px 15px 0 15px;
  /* margin: 0px 15px 0 15px; */
  border-radius: 8px;
}

.folder_active,
.all_folder_active {
  display: block;
  background-color: #fff;
  padding: 2px 0px 3px 8px;
  /* margin: 6px 15px 0 15px; */
  margin: 0px 15px 0 15px;
  border-radius: 8px;
}
.allsong-new.all_folder_active span {
  color: #1c82d1;
  padding-left: 8px;
  font-size: 13px;
}

.folder_wrapper .song .recent-song-inactive img {
  width: 20px;
  height: auto;
}

.folder_wrapper .song .recent-song-inactive span {
  font-size: 12px;
}

.folder_wrapper .song .recent-song-inactive {
  padding: 2px 0px 3px 8px;
  margin: 0px 15px 0 15px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.allsong-new.all_folder_active {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.recent-song-inactive {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.allsong-new img {
  width: 19px;
  height: auto;
}

.profile-sidebar.d-flex {
  position: absolute;
  left: 20px;
}

.sidebar-content-wrapper {
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100vh - 145px);
  margin-bottom: 5px;
}

.logout-new a,
.logout-new a:hover {
  text-decoration: none;
  color: #fff;
  padding-left: 7px;
}
.rightASideWrapper {
  background: #ffffff;
  height: 100vh;
  border: 1px solid #ededed;
  margin: 10px 10px 0 10px;
  border-radius: 12px;
  width: 63%;
}

.MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.toolbar-wrapper {
  padding: 0 10px 0px 10px !important;
}

.select-all-icon,
.select-btn span {
  border: dashed 1px #1f8de4;
  border-radius: 4px;
  padding: 0 5px 0 5px !important;
  background-color: transparent;
  font-size: 13px;
  color: #1e8de3;
  text-align: center;
  cursor: pointer;
  user-select: none;
}
.select-btn span {
  border: none;
}

button.move-to-icon {
  border: dashed 1px #565656;
  border-radius: 4px;
  padding: 0 5px 0 5px !important;
  background-color: transparent;
  font-size: 13px;
  color: #565656;
  text-align: center;
}
button.delete-icon {
  border: dashed 1px #f16363;
  border-radius: 4px;
  padding: 0 5px 0 5px !important;
  background-color: transparent;
  font-size: 13px;
  color: #f16363;
  text-align: center;
}
button.cancel-song-new {
  background-color: transparent;
  border: 1px solid #8e8e8e;
  padding: 2px 7px 2px 7px;
  border-radius: 4px;
  color: #8e8e8e;
  font-size: 13px;
}
.song-filter-icon {
  padding-top: 0px;
  margin-bottom: 2px;
  display: flex;
}
.song-filter-icon p {
  font-size: 12px;
  width: 105px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: 5px 0 0px 8px;
}
.song-filter-icon span {
  font-size: 13px;
  padding: 0 5px 0 5px;
  font-weight: 500;
}
.song_list_wrapper {
  border: 1px solid #eaecf0;
  height: calc(100vh - 180px);
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 0 0px 12px 12px;
  border-bottom: 1px solid #eaecf0;
}

.empty-song-list {
  font-size: 11px;
  color: #6b6b6b;
  padding: 15rem 0rem 0rem 6rem;
}

.full.song_list_wrapper {
  height: calc(100vh - 137px);
  max-width: 269px;
}

.full.song_list_wrapper.beats_wrapper {
  max-width: 100%;
  height: calc(100vh - 400px);
}

.beats_wrapper .date-size-new {
  display: flex;
  gap: 50px;
}

.head-all-song-list {
  background-color: #f2f3f5;
  padding: 6px 10px;
  border-radius: 12px 12px 0 0;
  border-bottom: 1px solid #f2f3f5;
}

.beats_container button.cancel-song-new {
  border: none;
  color: #208de3;
  position: relative;
  left: 22px;
  text-align: center;
  line-height: inherit;
  top: 2px;
}

.beat-search form.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
  height: auto;
  border-left: 5px;
  box-shadow: none;
  border: 1px solid #d3d3d3;
}

.beat-search img {
  width: 15px;
  margin-left: 7px;
}

.beat-search {
  margin-bottom: 6px;
}

.beat-options {
  display: flex;
  gap: 22px;
  justify-content: center;
  align-items: center;
}

.head-all-beats-list {
  line-height: initial;
  height: 32px;
  background-color: #f2f3f5;
  padding: 9px 10px;
  border-radius: 12px 12px 0 0;
  border-bottom: 1px solid #f2f3f5;
}

.head-all-beats-list p {
  color: #212529;
  font-size: 13px;
  font-weight: 500;
}

.head-all-song-list p {
  color: #212529;
  font-size: 13px;
  font-weight: 500;
}
.song-list-new {
  border-bottom: 1px solid #eaecf0;
  padding: 8px;
  cursor: pointer;
}
.song-list-new h5 {
  font-size: 12px;
  color: #0e080d;
  font-family: 'Raleway', sans-serif !important;
  font-weight: 500;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.song-list-new p {
  font-size: 12px;
  color: #8e8e8e;
}

.song-list-new.active {
  background-color: #f8fcff;
  border-bottom: 1px solid #1c82d1;
}
button.add-block-btn-new {
  border: 0;
  background-color: #1e8ce2;
  color: #fff;
  padding: 5px;
  border-radius: 7px;
  font-size: 12px;
}
.outer-accr {
  border: 1px solid #dedede;
  border-radius: 5px;
  padding: 5px;
  margin-top: 10px;
}
button.add-block-btn-new img {
  height: auto;
  width: 18px;
  margin-right: 5px;
}

.beats_container .add-btn-right-bar.new-right {
  border-radius: 20px;
}

.add-btn-right-bar.new-right {
  border: dotted 2px #1e8ce2;
  border-radius: 5px;
  width: 30%;
  margin: 5px auto;
  color: #1e8ce2;
}
.add-btn-right-bar.new-right span {
  font-size: 13px;
  padding: 0px 0 0 3px;
}
.menu-new {
  margin-left: 15px;
  cursor: auto;
  padding-top: 2px;
  cursor: pointer;
}
.count-no-ai-new p {
  position: absolute;
  right: 10px;
  top: -28px;
  color: #828384;
  font-size: 13px;
}
.count-no-ai-new {
  position: relative;
}
.add-track-new span {
  font-size: 11px;
}
.add-track-new {
  margin-left: 5px;
  min-width: 75px;
}

button.toolbar-icons-new {
  padding: 0;
  margin: 3px;
}

button.center-tool-icon {
  padding: 0 !important;
  margin: 3px !important;
}
button.right-tool-icon {
  padding: 0 !important;
  margin: 3px !important;
}
img.close-icon-m {
  position: absolute;
  right: 8px;
  box-shadow: 0 1px 8px 0 #cdcdcd;
  border-radius: 7px;
  top: 7px;
  cursor: pointer;
}
button.cancelBtn {
  background-color: #fff !important;
  color: #9ca3af !important;
  border: 1px solid #e5e7eb;
  text-transform: unset;
}
button.cancelBtn:hover {
  color: #9ca3af !important;
  background-color: #fff !important;
}
button.cancelBtn.danger {
  background-color: #ea6163 !important;
  color: #fff !important;
  border: 1px solid #e5e7eb;
  text-transform: unset;
}
button.cancelBtn.danger:hover {
  color: #fff !important;
  background-color: #ea6163 !important;
}
button.submitBtn {
  text-transform: unset;
}
button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.is-active.css-78trlr-MuiButtonBase-root-MuiIconButton-root {
  padding: 0 !important;
  margin: 3px !important;
}

.right-header-icons .MuiTabs-scroller.MuiTabs-fixed {
  background-color: #fff !important;
  height: 40px;
  margin-bottom: 7px !important;
}

.left-bar
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation0.MuiDrawer-paper.MuiDrawer-paperAnchorLeft {
  border: 0px !important;
}

.toolbar-new {
  margin-top: 10px;
  border-radius: 10px 10px 0 0;
  background-color: #fff !important;
  border: 1px solid #ededed;
}

.toolbar-new.left-sidebar-active {
  width: calc(100vw - 500px) !important;
  margin-right: 0px;
}

.toolbar-new.both-sidebar-active {
  width: calc(100vw - 807px) !important;
  margin-right: 307px;
}

.toolbar-new.toolbar-new.both-sidebar-active .right-toggle {
  display: none;
}
.toolbar-new {
  width: 100% !important;
  margin-left: -5px !important;
}

.toolbar-new .custom_editor_toolbar {
  justify-content: space-between;
  align-items: center;
}

.toolbar-new.inactive .custom_editor_toolbar {
  padding-left: 20px;
}

.toolbar-new.inactive .right-icon-close-new {
  padding-left: 10px;
}
.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl {
  border: 1px solid #ededed;
  border-bottom: 0 !important;
  padding: 4px;
  border-radius: 5px;
  margin-top: 30px;
  outline: none !important;
}

.right-top-header.Mui-selected {
  color: #1976d2;
  background-color: #1c82d1;
  padding: 0;
  width: 30px !important;
  border-radius: 50px;
  margin: 9px !important;
  min-height: auto;
}
.MuiDrawer-paper {
  width: 525px;
  box-sizing: border-box !important;
  background-color: transparent !important;
}
.acrro-right-icon img {
  margin-right: 6px;
}
/* end-rework-css */

/* end-new-design-css */

MAIN PAGE CSS .mainPageWrapper {
  display: flex;
}

.aside {
  display: flex;
  height: 100vh;
  width: 500px;
}

.shiftRight .w-110 {
  width: 65px !important;
}

.w-110 {
  width: 85px !important;
}

.col-lg-2.text-left.d-flex.align-items-center.lang_verse {
  padding-left: 3.3rem;
  width: 85px;
}

.leftASideWrapper {
  background: linear-gradient(90deg, #1c82d1 0%, #2092eb 100%);
  box-shadow: 4px 0px 9px 0px rgba(86, 86, 86, 0.15);
}
button:focus {
  outline: none !important;
}
.leftASideWrapper span.add_btn {
  color: #fff;
  font-size: 13px;
  font-weight: 300;
}

.logoImg {
  height: 41px;
  width: 190px;
  margin-top: 12px;
  margin-bottom: 12px;
}
.addSong {
  padding-left: 2rem;
  margin: 5px 0 0px 0;
}
img.left-bar-close-new {
  margin-left: 10px;
  margin-top: 0px;
}

.search-right-icons {
  margin-right: 10px;
}
.addFolder {
  padding-left: 2rem;
  line-height: 40px;
}

.addFolder img {
  margin-right: 10px;
}
.addSong img {
  margin-right: 10px;
}
.addFolder img {
  width: 20px;
}
.addSong img {
  width: 20px;
}
.songFolders {
  line-height: 29px;
}

.innerRecentSongs span,
.innerSongFolders span {
  padding-left: 8px;
}

.profile-text.text-right {
  padding-top: 2px;
  cursor: pointer;
  font-family: 'Roboto Condensed', sans-serif;
}
.cursor {
  cursor: pointer;
}
.profile-text.text-right a {
  color: #fff;
  text-decoration: none;
  padding: 0px 0 0 7px;
}
.logout-btn {
  margin: 2px 0 0 7px;
}
p.free {
  font-size: 10px;
  position: absolute;
  padding: 7px 0 0 20px;
  color: #333;
  font-weight: 700;
}
.text_label {
  color: #333 !important;
  transform: translate(0, -1.5px) scale(1) !important;
}

.submitBtn,
.cancelBtn {
  padding: 6px 25px !important;
  margin: 0 10px !important;
  overflow-y: auto !important;
  cursor: pointer !important;
  color: #fff !important;
  border-radius: 5px !important;
  background: #2092eb !important;
  font-weight: 400 !important;
}

.submitBtn:hover,
.cancelBtn:hover {
  background: #2092eb !important;
  transition: 10ms;
}

.rec-sec svg {
  fill: #1d87d9;
}
.form-control:focus {
  border-top: none;
  border-bottom: 1px solid #000 !important;
  border-left: none;
  border-right: none;
  border-radius: 0px;
  box-shadow: none !important;
}

textarea#exampleForm\.ControlTextarea1 {
  border-radius: 0px;
  box-shadow: none !important;
  border: 1px solid #ededed;
  border-radius: 5px;
}
.backTrack_select {
  position: relative;

  --max-lines: 1;
  --line-height: 1.4;
  max-height: calc(var(--max-lines) * 1em * var(--line-height));
  line-height: var(--line-height);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 8vw;
}

.backTrack_select_footer {
  position: relative;

  --max-lines: 1;
  --line-height: 1.4;
  max-height: calc(var(--max-lines) * 1em * var(--line-height));
  line-height: var(--line-height);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 145px !important;
}

.searchInput form {
  background-color: #ffffff;
  box-shadow: none;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  width: 185px;
  margin: 6px 22px 6px 10px;
  padding: 3px 0 3px 7px;
}

img.loader-img {
  margin: -7px 0 0 5px;
  width: 16px;
  height: auto;
}
.Beats-Producer {
  padding: 5px 10px 5px 10px;
  border-bottom: 1px solid #eaecf0;
}
.Beats-Producer.active {
  background-color: #f8fcff;
  border-bottom: 1px solid #1c82d1;
}
.select-text,
.loader-img {
  color: #1a5b8f;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
}

.select-btn {
  right: 0px;
  position: relative;
  justify-content: end;
  align-items: baseline;
  display: flex;
}
.right-header.css-fiov70 {
  min-height: 52px !important;
  /* height: 48px !important; */
}
.select-text:hover,
.loader-img:hover {
  color: #0e4674;
  font-weight: 500;
}

.script-name h5,
.script-head p,
.script-box {
  user-select: none;
}

.script-name {
  width: 200px;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  top: 5px;
}
.script-name h6 {
  font-family: 'Raleway', sans-serif !important;
  color: #565656;
  font-weight: 600;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 14px;
  /* width: 70%; */
}
.line {
  width: 100%;
  /* height: 1px; */
  border-bottom: 1px solid #d2d2d2;
  background-color: #d2d2d2;
}
.script-head p {
  font-size: 13px;
  font-weight: 500;
}
.script-box h5 {
  font-size: 14px;
  --max-lines: 1;
  --line-height: 1.4;
  max-height: calc(var(--max-lines) * 1em * var(--line-height));
  line-height: var(--line-height);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 10vw;
}
.script-box p {
  font-size: 12px;
  color: #6f6f6f;
}
.script-box h6 {
  font-size: 13px;
  font-weight: 300;
  color: #6f6f6f;
  /* min-height: 30px;
  max-height: 50px; */
  overflow: hidden;
  width: 22vw;
  /* display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis; */
}
.song.side-menu-song-recent {
  width: 160px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.editor_content.full_width_editor,
.editor_content {
  width: 100%;
}
.script-list {
  /* min-height: calc(72vh - 100px); */
  padding: 0;
  list-style: none;
  -webkit-mask-image: linear-gradient(to top, transparent, #eaecf0),
    linear-gradient(to left, transparent 17px, #eaecf0 17px);
  -webkit-mask-size: 100% 20000px;
  -webkit-mask-position: left bottom;
  transition: mask-position 0.3s, -webkit-mask-position 0.3s;
}

li.script-box {
  position: relative;
  padding: 6px 0px 5px 0px;
  cursor: pointer;
  min-height: 65px;
}

li.script-box.active_song {
  /* background-color: #fff; */
  border-radius: 5px;
  width: 300px;
}

.custom-search div {
  margin: 0;
}
.custom-search button {
  padding: 0;
}
.custom-search input {
  padding: 0 0 0 8px !important;
  height: 25px !important;
  font-size: 13px;
}

.searchInput form img {
  width: 15px;
  height: auto;
}

.custom-search input::placeholder {
  font-size: 12px !important;
}
.search-icon {
  width: 1.25rem !important;
  height: 1.5rem !important;
}

.search-bar input::placeholder {
  font-size: 12px !important;
}

.stop-footer-icon {
  fill: #1f8ee4 !important;
}
/* tab-css */

.cuatom-tab-bar button {
  width: 100px;
}
.cuatom-tab-bar span {
  background-color: transparent;
}
.cuatom-tab-bar .MuiTabs-root {
  min-height: unset;
  background: #eaecf08c;
  border-radius: 5px;
  margin-top: 8px;
}

.custom_tab.Mui-selected {
  color: #fff !important;
  border: 1px solid #e1e1e1 !important;
  background-image: linear-gradient(
    to bottom right,
    #1c82d1,
    #2092eb
  ) !important;
  padding: 0px !important;
  min-height: 2px !important;
  height: 32px;
  border-radius: 5px !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  text-transform: capitalize;
}

.ai-lyrical > p {
  color: #525252;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 233.333% */
  letter-spacing: 0.2px;
}
.balance-reobon.ai_btn {
  width: auto;
  display: flex;
  justify-content: end;
  min-width: 110px;
}

.balance-reobon span {
  color: #525252;
  text-align: right;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 233.333% */
  letter-spacing: 0.2px;
  font-family: 'Roboto Condensed', sans-serif;
}

/* .css-1h9z7r5-MuiButtonBase-root-MuiTab-root { */
.custom_tab {
  color: #2092eb !important;
  /* border: 1px solid #e1e1e1 !important; */
  /* background-color: #f9f9f9 !important; */
  padding: 0px !important;
  min-height: 2px !important;
  height: 30px;
  border-radius: 5px !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  text-transform: capitalize !important;
}
.add-block {
  text-align: right;
}
img.add-block-img {
  margin: 0 0 0 5px;
}
.select-custom-list {
  position: absolute;
  right: 0px;
  top: 30px;
}
.row.script-box.p-3 {
  position: relative;
}
.song-block {
  border: 1px solid #eaecf0;
  border-radius: 10px;
  padding: 0 0 9px 0;
  margin-bottom: 10px;
  overflow: hidden;
}
.song-box-header {
  border-radius: 5px 5px 0 0;
  padding: 5px 10px;
  border-bottom: 1px solid #eaecf0;
}
.song_heading_edit {
  margin: 0px !important;
}
.dots-and-drag {
  display: flex;
  float: right;
  position: relative;
  z-index: 99;
}

.song_heading_edit input {
  font-size: 16px;
  padding: 5px 0px;
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 90%;
}

.song-box-header .song_heading_edit input {
  font-size: 13px;
  font-weight: 500;
  padding: 7px 0px;
}

.song-block-content {
  padding: 1rem;
  overflow-y: auto;
  min-height: 20vh;
  max-height: 25vh;
  /* width: 56vw; */
}

.song-block-content.full-width {
  width: 96vw;
}
.song-block-content .ProseMirror {
  padding-right: 1.9rem;
}
.disable,
.disabled {
  cursor: not-allowed !important;
  pointer-events: none;
}
.disabled.auto {
  pointer-events: auto;
}

.ai-options {
  /* margin-right: 10px; */
}

.ai-options img {
  padding: 0 2px;
}
.block_editor {
  position: relative;
  cursor: initial;
}

.play-btn {
  background: #ffffff;
  padding: 0 0 0 0px;
  margin: 0px 0px -10px 0;
}
img.play-btnn.new {
  width: 22px;
  margin: 10px 7px 0 0;
}
.round-blue .MuiTabs-flexContainer {
  display: flex;
  justify-content: center;
}
.play-btn {
  text-align: right;
  position: absolute;
  align-items: center;
  right: 6px;
  bottom: 8px;
  cursor: pointer;
  width: 35px;
  z-index: 99;
  cursor: pointer;
}

.song-block-btns {
  position: absolute;
  bottom: 0px;
  right: 12px;
  z-index: 999;
  background: #fff;
}
.song-block-btns .play-btn {
  position: unset;
}

grammarly-extension {
  display: none !important;
}

.edit-section {
  text-align: end;
  margin-top: 25px;
}

.song-blocks-content input::after {
  border-bottom: 0 !important;
}
.song-blocks-content .MuiFormControl-marginNormal {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-thumb {
  background-color: #eaecf0;
  border-radius: 10px;
  border: 3px solid #ffffff;
}

.folder_wrapper::-webkit-scrollbar,
.sidebar-content-wrapper::-webkit-scrollbar {
  width: 5px;
}

.folder_wrapper::-webkit-scrollbar-thumb,
.sidebar-content-wrapper::-webkit-scrollbar-thumb {
  border: 3px solid #ffffff;
  background-color: #eaecf0;
}

.MuiDrawer-paper {
  overflow: hidden !important;
}
.font-select,
.font-size {
  min-width: 75px;
  margin: 0 7px 0 0px;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  /* min-height: auto !important;
  height: 33px !important; */
}

.font-select .MuiSelect-select,
.font-size .MuiSelect-select {
  height: 18px !important;
  min-height: unset !important;
  font-size: 14px !important;
  /* padding: 6px 28px 6px 8px !important; */
  background: transparent !important;
  width: 100%;
}

.font-weight-normal {
  font-size: 13px;
  font-weight: 400;
}

img.toolbar-btn {
  width: 33px;
  height: auto;
  margin-bottom: 2px;
}
.font-select .MuiSelect-select.MuiInputBase-input,
.font-size .MuiSelect-select.MuiInputBase-input {
  padding: 4px 23px 8px 3px !important;
}

.font-size {
  min-width: unset;
}
.input-custom {
  padding: 4px 0 4px 0;
  border-bottom: 1px solid #eeeeee;
  justify-content: space-between;
}

/* .MuiTabPanel-root {
  height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
} */
/* .footer-custom {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
} */
.footer-bottom-content {
  background-color: #f8fcff;
  padding: 2px 10px 2px 15px !important;
  border-radius: 12px;
  border: 1px solid #2090e7;
  position: fixed;
}
.tick-with-text p {
  display: flex;
}
/* .song-card-bottom {
  position: absolute;
  bottom: 0;
  background-color: #f9f9f9;
  width: 67%;
  height: 60px;
  border: 1px solid #e8e8e8;
} */
/* .select-font-inner {
  line-height: 21px !important;
} */

.all_song_btn {
  padding-top: 1px;
  padding-bottom: 1px;
  top: -5px;
}

.all_f_name {
  position: relative;
  left: 27px;
}

.drag_idea_list {
  overflow-y: auto;
  min-height: 100px;
  /* max-height: 58vh;
  height: 58rem; */
}

.buttons-right-bar ul {
  padding: 0;
}

/* media ***********************/
.script-list ul {
  /* height: 79vh; */
  overflow: scroll;
  overflow-x: clip;
}

.song_block_content {
  overflow-x: hidden;
  height: 100%;
  max-height: calc(80vh - 60px);
  overflow-y: auto;
}
.category_list {
  /* overflow-x: hidden;
  height: 100%;
  max-height: calc(100vh - 290px);
  overflow-y: auto; */
  /* padding: 10px 0; */
}

.song-list-name h5 {
  --max-lines: 1;
  --line-height: 1.4;
  max-height: calc(var(--max-lines) * 1em * var(--line-height));
  line-height: var(--line-height);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #2092eb;
  /* font-family: 'Raleway', sans-serif !important; */
  font-size: 15px;
  font-weight: 400;
}
.right-side-tab p {
  /* font-family: 'Raleway', sans-serif !important; */
  font-weight: 500;
  font-size: 12px;
}
.song {
  cursor: pointer;
}
.song-list-name h5 {
  font-size: 16px;
  color: #2092eb;
}
.folder_contextMenu {
  position: fixed;
  background: #fff;
  z-index: 9999;
}

.contextMenu--option,
.contextMenu--option .c_span {
  padding: 6px 50px 5px 10px;
  min-width: 160px;
  cursor: default;
  color: #000 !important;
  font-size: 15px !important;
  font-weight: 400 !important;
}

.contextMenu--option .c_span:hover {
  color: white !important;
}

.contextMenu--option:hover {
  background: #1d8bdf;
  color: #fff !important;
}
.contextMenu--option:active {
  background: #1d8bdf;
  color: #fff !important;
}
.contextMenu--option__disabled {
  color: #999;
  pointer-events: none;
}
.contextMenu--separator {
  width: 100%;
  height: 1px;
  background: #ccc;
  margin: 0 0 0 0;
}

.main_inner_folder {
  position: relative;
  padding-top: 1px;
}

.folder_checkbox {
  position: absolute !important;
  right: -10px !important;
  top: -4px !important;
  padding-left: 7px !important;
}

.folder_names {
  width: 140px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  padding: 7px 0px 0px 20px;
  margin: 0px;
  border-radius: 8px;
  font-size: 13px;
}
.download_icon {
  border-radius: 50px;
  width: 28px;
  height: 28px;
  top: 8px;
}
.move-select-delete-btn span {
  color: #1a5b8f;
  margin: 0 10px 0 10px;
  user-select: none;
  cursor: pointer;
}
img.trash_icon {
  margin: -4px 0 0 0;
}
.tabs-outer-bg {
  background-color: #f9fafb;
  border-radius: 4px !important;
}
.right-tab-custom {
  background-color: transparent !important;
  color: #2092eb !important;
  border: 0 !important;
  min-height: 24px !important;
  padding: 5px 10px !important;
  font-size: 12px !important;
}
.Mui-selected.right-tab-custom {
  background-image: linear-gradient(
    to bottom right,
    #1c82d1,
    #2092eb
  ) !important;
  font-weight: 400 !important;
  color: #fff !important;
  border-radius: 4px !important;
  font-size: 12px !important;
}
.drag-btn-right-bar h4 {
  font-size: 17px;
  padding-top: 10px;
}
.drag-btn-right-bar span {
  font-size: 13px;
  color: #8e8e8e;
}
.Mui-selected.right-tab-custom.chordnotion-tab {
  background-color: #fff !important;
  color: #fff !important;
  border-radius: 4px !important;
  width: 50%;
}
.right-tab-custom.chordnotion-tab {
  background-color: transparent !important;
  color: #2092eb !important;
  border: 0 !important;
  width: 50%;
  padding: 0 3px 0 0 !important;
  font-size: 12px;
}
.round-blue {
  min-height: 0 !important;
}
.tabs-outer-bg span {
  background-color: transparent;
}
.right-top-header {
  min-width: 0px !important;
}
.Mui-selected.right-top-header svg path {
  fill: transparent;
  stroke: #fff;
}
.Mui-selected.right-top-header svg.music_icon path {
  fill: none !important;
  stroke: #2092eb;
}
.right-header-icons span {
  background-color: transparent;
}

ol.lookupcont li {
  text-align: left !important;
  border: 0;
  padding: 0;
}
ol.lookupcont {
  padding-left: 1rem;
}
.slide-tabs {
  margin-top: 1rem;
  min-height: 0 !important;
  /* padding: 5px 0 5px 0; */
  padding: 0;
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  font-size: 12px !important;
}
.slide-tabs span {
  background-color: transparent;
}
.slide-tabs button {
  min-width: 30px;
  border: 0 !important;
  background-color: transparent !important;
}
.Mui-selected.chord-tabs-custom {
  border: 0 !important;
  color: #333 !important;
}
.chord-tabs-custom {
  color: #5384ab !important;
}

/*
tabs-arrow-style
 border: 1px solid #333;
    border-radius: 50px;
    width: 25px;
    height: 25px;
    margin: 6px 0 0 0px; */

.rside {
  /* border-top: 1px solid #ededee; */
}
.share-and-delete img {
  margin: 0 8px 0 0;
}
.share-and-delete {
  margin-top: 3px;
}
section.outer-bg-volume {
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  padding: 10px 0 10px 0;
}

.backing-tab-wrapper .outer-bg-volume .sound-volume .volume-bar input {
  width: 80px;
}

.backing-tab-wrapper .share-and-delete.d-flex.mt-0 {
  margin-left: 0.7rem;
  padding: 0 5px;
}
.custom-table-right-bar table {
  font-size: 13px;
}

.custom-table-right-bar .recording-header {
  display: flex;
  justify-content: space-between;
  border: 1px solid #f2f3f5;
  border-radius: 7px;
  margin: 7px 7px -2px 7px;
  font-size: 12px;
  align-items: center;
  padding: 6px;
  font-weight: 500;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background: #f2f3f5;
  color: #212529;
}

.custom-table-right-bar .recording-header p {
  margin: 0 45px 0px 0px;
}

.recording-header {
  display: flex;
  justify-content: space-between;
}

.track-list.cursor {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  border-bottom: 1px solid #eaecf0;
}

.rec_list {
  /* height: calc(100vh - 226px); */
  height: calc(100vh - 263px);
  overflow: auto;
}
.song-item-wrapper {
  margin-left: 5px;
}

.song-item-wrapper .song-track {
  font-size: 12px;
  font-weight: 500;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.song-item-wrapper .song-duration {
  font-size: 12px;
  font-weight: 300;
  margin: -3px 0 -5px 1px;
}

.rec_list {
  border: 1px solid #e5e5e6;
  border-radius: 8px;
  margin: 2px 7px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  padding-top: 1px;
  border-top: 0;
}
/* .custom-table-right-bar.tableWrap {
  overflow-y: scroll;
 
  overflow-x: hidden;
  height: 100%;
  max-height: calc(69vh - 100px);
} */
span.song-track {
  padding: 0px 0px 0px 8px;
  user-select: none;
  min-width: 120px;
  max-width: 175px;

  --max-lines: 1;
  --line-height: 1.4;
  max-height: calc(var(--max-lines) * 1em * var(--line-height));
  line-height: var(--line-height);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.recording_play_icon {
  width: 18px;
  height: auto;
}

.beat-author {
  font-size: 11px;
  font-weight: 300;
  margin: -3px 0 -5px 1px;
}

tr.track-list td {
  padding: 10px 7px 10px 7px;
  position: relative;
  font-size: 12px;
}
.track-list.active {
  background-color: #f8fcff;
  border-bottom: 1px solid #1c82d1;
}
tr.track-list.active {
  background-color: #f8fcff;
  border-bottom: 1px solid #1c82d1;
}
.edit-icon-right-bar span {
  font-size: 13px;
  color: #5384ab;
  font-weight: 600;
  padding-left: 4px;
}

.edit-icon-right-bar {
  padding-left: 8px;
}

.backing-tab-wrapper .edit-icon-right-bar {
  display: flex;
}

.backing-tab-wrapper .edit-recording-title {
  width: 20px;
  height: 30px;
}

.backing-tab-wrapper .edit-icon-right-bar label {
  padding: 8px 0px 7px 4px;
}
.slide-tabs.tarck-list {
  margin: 0;
}

.sound-volume .volume-bar {
  margin-left: 2px;
}

.sound-volume .volume-bar img.volume-icon-left {
  height: 23px;
}
.sound-volume .volume-bar img.volume-icon-right {
  height: 23px;
}

.dialog_content {
  padding: 0px 24px !important ;
}

.alert_modal_error {
  background: transparent !important;
  padding: 0px !important;
}

.MuiFormLabel-asterisk {
  color: red !important;
}
.MuiInputLabel-root,
.MuiTypography-root,
label {
  user-select: none !important;
}
.folder_input .MuiInputBase-root input {
  margin-top: 2px !important;
}
.MuiInputBase-root input {
  font-size: 14px;
}
span.take-name {
  margin-left: 13px;
  margin-top: 6px;
  user-select: none;
  font-size: 13px;
  font-weight: 500;
}
span.text-dark.volume-custom,
span.text-dark.volume-max-custom {
  margin-left: 5px;
  -webkit-user-select: none;
  user-select: none;
  color: #212529 !important;
  font-size: 12px;
  /* bottom: 3px; */
  position: relative;
  font-weight: 400;
}

.text-dark.volume-max-custom {
  margin-left: 0px !important;
}
input.play-bar {
  /* margin-top: 9px; */
  /* width: calc(100% - 100px); */
}
.volume-bar {
  margin-top: 7px;
  margin-left: 10px;
}
.drum-custom {
  padding-top: 2px;
  margin-left: 10px;
}
span.backing-text {
  color: #919191;
  padding-left: 5px;
  user-select: none;
  cursor: pointer;
}
span.backing-text-list {
  color: #2092eb;
  padding-left: 5px;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  font-size: 13px;
  font-family: 'Roboto Condensed', sans-serif;
}

svg.footer-drum-icon.active path {
  fill: #333;
}
.Beats-Producer.active {
  background-color: #f8fcff;
  border-bottom: 1px solid #1c82d1;
  padding: 5px 10px;
}
.footer-active {
  width: calc(100vw - 524px);
  margin-left: 524px;
}

.MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.23);
}

.custom_modal {
  width: 600px !important;
}

.song_heading_edit .MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.select_menu {
  font-size: 1.2rem !important;

  line-height: 1.4375em !important;
  letter-spacing: 0.00938em !important;
  color: #333 !important;
  left: -13px !important;
  margin-bottom: -10px !important;
  position: relative !important;
}

.search-input {
  width: 80%;
  outline: none;
  border: none;
}

.record-drum-sec {
  display: flex;
  /* width: 270px; */
  background-color: #f8fcff;
  padding: 5px;
  border-radius: 50px;
  border: 1px solid #2090e8;
  margin: 0 10px 0 0;
  -webkit-user-select: none;
  user-select: none;
}

.record-drum-sec {
  float: right;
  justify-content: end;
  align-items: end;
  position: absolute;
  text-align: center;
  right: 0px;
  bottom: -3px;
  z-index: 999;
}
.record-drum-sec.play-block-audio {
  width: 69px;
  background: transparent;
  border: none;
}

.record-drum-sec span {
  padding: 0 0px 0 5px;
}

.rec-sec span {
  font-size: 13px;
  color: #8e8e8f;
}
.drum-sec {
  margin-left: 10px;
}
span.backing-track-sc {
  color: #1f8ee5eb;
}

.play-btn.pause-block,
.pause-footer-icon,
.recording_pause_icon {
  width: 24px;
  height: auto;
  color: #1f8ee5eb;
  cursor: pointer;
  right: 7px;
  z-index: 999;
  border-radius: 50%;
}

.recording_pause_icon {
  width: 22px;
  margin: 0 0px 0 -3px;
}
.pause-footer-icon {
  width: 25px;
}
.pause-block {
  cursor: pointer;
}

.block-cancel {
  width: 16px;
  text-align: center;
  cursor: pointer;
}
.record-drum-sec.play-block-audio .block-cancel {
  width: 18px;
  text-align: center;
  cursor: pointer;
  margin-left: 4px;
}

#select-input::-moz-selection {
  /* Code for Firefox */

  background: none;
}

#select-input::selection {
  background: none;
}

#select-input {
  color: #696969;
  border: none;
  cursor: pointer;
  /* max-width: 35px; */
  font-size: 13px;
}

#select-input:focus-visible {
  border: none;
  outline: none;
}

span.download-msg {
  color: #000;
  font-weight: 500;
  margin-top: 12px;
  position: absolute;
  right: 10px;
  font-size: 10px;
}

.track-list.active {
  background-color: #f8fcff;
  border-bottom: 1px solid #1c82d1;
}

.ProseMirror {
  padding-top: 10px;
  z-index: 999;
}

.ProseMirror p {
  position: relative !important;
  line-height: 1.6rem;
}

.ProseMirror p li {
  text-decoration: none;
  list-style: none !important;
}

.ProseMirror p > s {
  text-decoration: auto;
  line-height: 0;
  position: absolute;
  font-weight: 500;
  color: #000;
}

s {
  text-decoration: auto;
}
strong {
  position: relative !important;
}
strong s {
  position: absolute;
  bottom: 10px;
  font-size: 12px !important;
}

li.right-bar-btn-list > s::-moz-selection {
  /* Code for Firefox */

  background: transparent;
}

li.right-bar-btn-list > s::selection {
  background: transparent;
}

.right-bar-btn-list > s {
  user-select: all !important;
}

.block_editor .ProseMirror {
  min-height: 50px;
  max-height: 150px;
  overflow-y: scroll;
  line-break: true;
  overflow-x: hidden;
  width: 100%;
  /* width: calc(100% - 35px); */
}

.block_editor .ProseMirror > ul > li {
  list-style: none !important;
}

.ProseMirror > ul > li {
  list-style: none !important;
}

.buttons-right-bar ul.chords {
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  /* height: 25rem; */
}

.login_right_side {
  width: 100%;
  display: table;
  margin: 0 auto;
  height: 100vh;
}
.login_form {
  margin: 0 auto;
  margin-top: 100px;
  display: table-cell;
  vertical-align: middle;
}
.login_left_side {
  width: 100%;
  display: table;
  margin: 0 auto;
  background-image: linear-gradient(to bottom right, #1c82d1, #2092eb);
  height: 100vh;
}
.logo.text-center {
  margin: 0 auto;
  margin-top: 100px;
  display: table-cell;
  vertical-align: middle;
}
button.submit_btn {
  height: 45px;
  background-color: #2092eb;
}
.login_right_side.sign_up {
  width: 80%;
}
img.vector_img {
  width: 80%;
}

.MuiAvatar-root.MuiAvatar-circular {
  background-color: #2092eb;
}

/* ul.drag_idea_list {
  height: 100%;
  max-height: calc(60vh - 100px);
  overflow-y: scroll;
} */

ul.drag_idea_list {
  max-height: calc(60vh - 60px);
  overflow-y: scroll;
  height: 100%;
}

.MuiDialog-root.MuiModal-root {
  z-index: 9992;
}

.recording-tab input.volume-bar-input.backTrackVolume {
  width: 100%;
}
ul.bottom-se.new-bottom.chords,
.buttons-right-bar ul.chords,
.song-blocks-content,
/* .category_list, */
.ProseMirror {
  -webkit-mask-image: linear-gradient(to top, transparent, #eaecf0),
    linear-gradient(to left, transparent 17px, #eaecf0 17px);
  -webkit-mask-size: 100% 20000px;
  -webkit-mask-position: left bottom;
  transition: mask-position 0.3s, -webkit-mask-position 0.3s;
}
ul.bottom-se.new-bottom.chords:hover,
.buttons-right-bar ul.chords:hover,
.script-list ul:hover,
.song-blocks-content:hover,
/* .category_list:hover, */
.ProseMirror:hover {
  -webkit-mask-position: left top;
}

/* 6-july-23 */
.French-Dreams-text {
  width: 90%;
}
.French-Dreams-text h5 {
  color: #2092eb;
  font-size: 16px;
  font-weight: 400;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.balance-reobon a {
  color: #2092eb;
  text-decoration: none;
  font-size: 12px;
  border: dashed 1px #1f8de4;
  padding: 5px;
  border-radius: 7px;
  margin-left: 4px;
}
.balance-reobon img {
  margin: 0 5px 0 0;
  width: 18px;
  height: auto;
  bottom: 2px;
  position: relative;
}

.gray-box-ai {
  padding-right: 8px;
}
.theme-input-ai input {
  height: 30px;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 7px;
  padding: 0 0 0 12px;
  font-size: 13px;
}
.language-select.select-custom-ai .MuiInputBase-input {
  font-size: 12px;
}

.ai-select-menu .MuiInputBase-input {
  height: 30px !important;
  padding: 2px 0px 0px 5px !important;
  border-radius: 8px !important;
}

.ai-select-menu {
  min-width: 248px !important;
  width: 100%;
  /* height: 20px !important; */
}

.theme-input-ai input::placeholder {
  font-size: 13px;
}
.Theme-text span {
  /* color: #ff5f00; */
  font-size: 17px;
}
.Theme-text p {
  color: #374151;
  font-size: 13px;
  font-family: 'Roboto Condensed', sans-serif;
}

.ai-option-two.rightToggle input {
  min-width: 160px;
  max-width: 260px;
}

.ai-option-two input {
  min-width: 260px;
  max-width: 350px;
}

.shiftRight .style-span {
  padding: 1px 8px 0;
}

.style-span {
  padding: 1px 15px 0px 15px;
  margin: auto;
  color: #363636;
}

.count-no-ai {
  color: #828384;
  text-align: left;
  font-family: Roboto;
  padding-left: 5px;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 280% */
  letter-spacing: 0.2px;
}
.theme-input-ai fieldset {
  border-style: none !important;
}
.custom-check-ai {
  display: flex;
  /* margin: 0 0 0 20px; */
}
.custom-check-ai p {
  margin: 10px 0 0 0;
}
.language-select .MuiInputBase-input {
  padding: 2.3px 7px !important;
}
.Generate-Lyric-btn button {
  background-image: linear-gradient(to bottom right, #1c82d1, #2092eb);
  border: 0;
  padding: 6px 8px;
  border-radius: 7px;
  color: #fff;
  margin-left: 9px;
  font-size: 12px;
}
.Generate-Lyric-btn img {
  /* padding: 0 0 0 10px; */
  width: 15px;
  font-size: 13px;
}
.Regenerate-btn button {
  background-color: #afafaf;
  border: 0;
  padding: 6px 15px;
  border-radius: 5px;
  color: #fff;
  border: 1px solid #e5e5e5;
}
.ai-footer-img img {
  width: 20px;
  margin: 0 20px 0 0;
}
img.copy-ai-img {
  width: 25px;
}
.count-no-ai p {
  font-size: 13px;
}

.add_track_btn:hover,
.add_track_btn span:focus-visible {
  background-color: transparent !important;
  outline: none;
  border: none;
}

.toolbar-wrapper.MuiToolbar-root {
  min-height: 53px !important;
}

.song-item-heading {
  padding: 0;
  margin: 0 0 0 -12px;
}

.song-item-date {
  margin-right: 12px;
}

.ai-assistant-main {
  position: relative;
  overflow-x: hidden;
  padding-right: 4px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  /* height: calc(100% - 80px); */
  height: 90%;
  overflow: hidden;
  /* height: 100%; */
}
.ai-assistant-main.ai_only {
  height: calc(100% - 5px) !important;
}

.right-side-tab .right-tab-custom {
  min-width: 54px;
}

.MuiTabPanel-root.song-block-wrapper {
  width: calc(100vw - 840px);
  padding-right: 0px !important;
  /* width: calc(100vw - 854px);
  padding-right: 12px !important; */
}

.MuiTabPanel-root.note-book-wrapper {
  width: calc(100vw - 340px);
  padding-right: 24px !important;
}

.MuiTabPanel-root.note-book-wrapper.hideToggles {
  padding-right: 24px !important;
}

/* AI Assistant  */

.grid-box-sec {
  display: grid;
  grid-template-columns: 13% 81% auto;
}

.grid-box-one {
  padding-left: 15px;
}
.grid-box-sec-two {
  display: grid;
  grid-template-columns: 13% 32% 8% 8% 33% 4%;
}

.grid-box-three {
  padding: 0 7px 0px 0px;
}
.grid-box-sec-three,
.grid-box-sec-four {
  display: grid;
  grid-template-columns: 13% 83%;
}

.hideToggles .grid-box-sec {
  grid-template-columns: 7% 90% auto;
}

.hideToggles .grid-box-sec-two {
  grid-template-columns: 7% 41% 4% 4% 41% 4%;
}

.hideToggles .grid-box-sec-three,
.hideToggles .grid-box-sec-four {
  grid-template-columns: 7% 83%;
}

.Generate-Lyric-btn button[disabled] {
  opacity: 0.8;
}

.footer-bottom-content.footer-bottom-active {
  /* width: calc(100% - 200px); */
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
}

.re-generate-btn {
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 233.333% */
  letter-spacing: 0.2px;
}

.footer-bottom-content.ai-footer.footer-bottom-active.d-flex {
  background-color: transparent;
  border: 0;
  width: calc(100% - -12px);
}
button.re-generate-footer-btn {
  background-color: #afafaf;
  border: 0;
  padding: 5px 12px 5px 12px;
  border-radius: 5px;
  color: #fff;
  font-size: 13px;
}
.word-count p {
  font-size: 13px;
}
.ai-footer-icon img,
.ai-footer-icon svg {
  margin: 0 5px 0 5px;
  cursor: pointer;
  stroke: #aeaeae !important;
}

.ai-footer-icon svg.active {
  stroke: #292929 !important;
}

.style-select-menu div#demo-customized-select {
  border-radius: 8px;
}

.footer-bottom-content.ai-footer.footer-bottom-in-active.d-flex {
  width: calc(100% - -12px);
  justify-content: space-around;
  background: transparent;
  border: none;
}

.song_heading_edit input.Mui-disabled,
.pt-4.notebook-tab-active .song_heading_edit input {
  opacity: 1;
  color: #2092eb;
  font-weight: 400;
  font-size: 16px;
}

.pt-4.notebook-tab-active {
  padding-top: 0.5rem !important;
}
.select-font-inner.font-select.selct-lang-cu {
  line-height: 20px !important;
}
.select-font-inner.font-size {
  line-height: 20px !important;
}
span.lint-play {
  margin: 0 3px 0 3px;
}
.new-note {
  text-align: right;
}
.tab-heading-text input {
  color: #1a5b8f;
}
.note-p-tag p {
  font-size: 15px;
  color: #525252;
}

.SP_AI_Generated {
  display: flex;
}
.SP_AI_Generated p {
  color: #2092eb;
  font-size: 13px;
  padding-left: 10px;
  font-weight: 500;
  font-family: 'Roboto Condensed', sans-serif;
}
.drop-text-ai.d-flex p {
  color: #525252;
  font-size: 12px;
  text-align: right;
  margin-right: 10px;
  font-family: 'Roboto Condensed', sans-serif;
}
.drop-text-ai.d-flex {
  float: right;
}
.select-menu-note {
  float: right;
  width: 100%;
}
.select-menu-note div .MuiSelect-select {
  height: 28px;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  padding: 0 0 0 12px;
  width: 85px;
  line-height: 28px;
  font-size: 13px !important;
}

.select-menu-note div .MuiSelect-select:focus {
  box-shadow: none !important;
}

.menu_option_btn {
  padding: 5px 10px !important;
  background: transparent !important;
  border: 1px solid #e5e5e5 !important;
  margin: -3px 7px !important;
  color: #000 !important;
  font-size: 12px !important;
}

/* .MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-sizeSmall.language-select.select-custom-ai.css-hrjh4n-MuiInputBase-root
  div {
  height: 30px;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  padding: 0 0 0 12px;
} */

div#mui-component-select-genre,
div#mui-component-select-verses,
div#demo-customized-select-lang {
  height: 26px !important;
  border-radius: 8px !important;
  font-size: 12px;
  background-color: #fff;
  border: 1px solid #e5e5e5;
}
.custom-check-ai div .MuiSelect-select {
  height: 30px !important;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 7px;
  padding: 0 0 0 12px;
}
/* .For-inspiration-select select {
  height: 30px;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  padding: 0 0 0 5px;
  font-size: 12px !important;
} */

.For-inspiration-select .select__control {
  font-size: 12px;
  min-height: 20px;
  border-color: #dedede !important;
}

.For-inspiration-select .select__option {
  padding: 2px 5px;
  margin: 0;
  font-size: 12px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  /* text-transform: capitalize; */
}

.select__value-container:focus-visible {
  outline: unset !important;
  border: none !important;
}

.select__indicator {
  padding: 4px 0px 4px 4px !important;
}
.select__indicator.select__clear-indicator svg {
  fill: #f16363;
  height: 16px;
  width: 16px;
  position: relative;
}
.select__menu-lis {
  margin: -1px 0 !important;
  max-height: 310px !important;
}
.select__indicator-separator {
  display: none;
}

.select__indicator svg {
  color: #2092eb;
}
.For-inspiration-select .select__control .select__control:hover {
  border: 1px solid #2092eb;
  border-color: #2092eb !important;
}

.add-block p {
  font-size: 12px;
  color: #000000;
}
.new-note p {
  font-size: 12px;
  color: #000000;
  margin-bottom: 0.5rem;
}

.select-menu-note p {
  margin-bottom: 0;
}
.clear-btn {
  color: #2092eb;
  text-decoration: none;
  font-size: 12px;
  border: dashed 1px #1f8de4;
  padding: 4px 10px 4px 10px;
  border-radius: 7px;
  margin-left: 15px;
  background-color: #fff;
}

.innerSongFolders {
  overflow-y: auto;
  height: calc(100vh - 480px);
  overflow-x: clip;
}

.songFolders {
  position: sticky;
}

.script_list_wrapper {
  position: sticky;

  width: 300px;
}

.script-list {
  overflow: scroll;
  /* height: calc(90vh - 80px); */
  height: calc(90vh - 137px);
  overflow-x: clip;
}

.script-list.card-footer-hide {
  overflow: scroll;
  height: calc(90vh - 80px);
  overflow-x: clip;
}

/* .ai-content-wrapper {
  position: sticky;
  overflow: scroll;
} */

.drop-text-ai {
  position: relative;
  bottom: 5px;
}

.drop-text-ai .ai-options img {
  width: 26px;
  height: auto;
  position: relative;
  bottom: 2px;
}

p.ai-content {
  height: calc(100vh - 390px);
  font-size: 13px;
  overflow: auto;
}
p.ai-content.note_ai {
  height: calc(100vh - 305px);
  font-size: 13px;
  overflow: auto;
}

.hide-tabs p.ai-content {
  height: calc(95vh - 230px);
}

.recent-song-inactive img.all-song.img {
  width: 20px;
  height: auto;
}
.col-lg-12.script_wrapper {
  position: sticky;
}

.song-blocks-content {
  position: sticky;
  /* overflow-y: auto;
  height: calc(100% - 425px);
  line-break: auto; */
}

.song-blocks-content .ProseMirror {
  overflow-y: auto;
  height: calc(100vh - 138px);
  line-break: auto;
}

.mini_editor .song-blocks-content .ProseMirror {
  height: calc(100vh - 260px);
}
.mini_editor .song-blocks-content.notes .ProseMirror {
  height: calc(100vh - 170px);
}

.full_editor .song-blocks-content .ProseMirror {
  overflow-y: auto;
  height: calc(100vh - 110px);
  line-break: auto;
}
ul.drag_idea_list {
  overflow-y: scroll;
  height: calc(100vh - 345px);
}

.drag-btn-right-bar {
  position: sticky;
  text-transform: capitalize;
}

.buttons-right-bar.sec {
  position: sticky;
}

ul.bottom-se.new-bottom.chords,
ul.bottom-tags-custom.chords {
  overflow: auto;
  height: calc(100vh - 460px);
}

tbody.rec_list {
  overflow: scroll;
  height: calc(100vh - 210px);
  position: absolute;
  padding: 0px 5px 0 5px;
}

ul.drag_idea_list.hide_footer {
  overflow-y: scroll;
  height: calc(100vh - 345px);
}

.one-line-clamp {
  text-overflow: ellipsis;

  /* Needed to make it work */
  overflow: hidden;
  white-space: nowrap;
}

.folder_wrapper {
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100vh - 548px);
  /* margin-top: 5px; */
}

.pro-img {
  width: 21px;
  height: auto;
}

.profile-text.text-right {
  font-size: 13px;
}

.logout-new {
  position: absolute;
  top: 25px;
  left: 2px;
}

.logout-new a,
.logout-new a:hover {
  font-size: 13px;
}

.download-icon {
  width: 35px;
  height: auto;
  margin-bottom: 5px;
}

.profile-footer-wrapper {
  position: relative;
  width: 100%;
  bottom: 3px;
  left: 3px;
}

.main-tab-content-wrapper {
  padding: 5px 0 0 12px;
  background: #fff;
  border: 1px solid #ededed;
  height: calc(100vh - 60px);
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.block-heading-wrapper {
  display: flex;
  gap: 10px;
}

.block_editor {
  padding: 0 10px;
}

.ai-header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.8rem;
}

.balance-reobon {
  width: 100%;
  display: flex;
  justify-content: end;
}

.ai-collapse-header {
  height: 30px !important;
  padding: 0 !important;
  min-height: 20px !important;
  font-weight: 500;
}
.MuiPaper-root.MuiAccordion-root.MuiAccordion-rounded {
  box-sizing: unset !important;
  box-shadow: none !important;
}

.ai-assistant-form-wrapper {
  border: 1px solid #e5e5e5 !important;
  border-radius: 8px !important;
  padding: 3px 0px 3px 8px !important;
}

.ai-collapse-header
  .MuiAccordionSummary-content.MuiAccordionSummary-contentGutters {
  font-size: 13px;
  margin: 0;
  padding: 0;
}

.pre-chorus-wrapper {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  /* padding: 1px 0px; */
  margin-left: -8px;
}

.pre-chorus-wrapper.btns {
  margin-top: -3px;
}

p.ai-content.ai-expand {
  height: calc(100vh - 590px);
  overflow: auto;
}

p.ai-content.ai-expand.note_ai {
  height: calc(100vh - 460px);
  overflow: auto;
}
.hide-tabs p.ai-content.ai-expand {
  height: calc(100vh - 490px);
}
.hide-tabs p.ai-content.ai-expand.ideas {
  height: calc(100vh - 470px);
}

.ai-assistant-form-wrapper::before {
  position: unset !important;
}

.checkbox-song {
  position: relative;
  top: 2px;
  left: 5px;
}

.move-option-wrapper ul {
  height: 200px !important;
}

.checkbox-song input[type='checkbox'] {
  accent-color: #208de4;
}

.ai-checkbox-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 4px 0px;
}

.footer-bottom-content.both-sidebar-active {
  /* right: 415px; */
  padding: 2px 9px;
  margin-right: 50px;
}

.footer-bottom-content.d-flex.right-sidebar-active {
  right: 42%;
}

.toolbar-new.right-header-sidebar-active {
  /* width: calc(100% - 346px) !important;
  margin-right: 333px !important; */

  /* width: calc(100% - 362px) !important;
  margin-right: 349px !important; */
  width: calc(100% - 316px) !important;
  margin-right: 307px !important;
}

.rightDrawer.right-header-sidebar-active {
  width: 20% !important;
  margin-left: 5px;
}

.right-header-sidebar-active .main-tab-content-wrapper {
  margin-left: 9px;
  margin-right: 13px;
}

.toolbar-new.right-header-sidebar-active .custom_editor_toolbar {
  padding-left: 10px;
}

.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl::before,
.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl::after {
  border-bottom: 1px solid #ededed !important;

  outline: none !important;
  /* border: none; */
}

.right-header {
  /* min-height: 49px !important; */

  min-height: 40px !important;
  border-bottom: 1px solid #e1e1e1;
}

.rightDrawer .MuiDrawer-paper {
  margin: 11px 0px 0px 0px;
  border: 1px solid #eeeeef;
  border-radius: 10px;
  background-color: #fff !important;
  /* border-top-left-radius: 0px; */
}

li.drag-btn-inner > svg > path {
  color: #1f8ce2 !important;
  fill: #1f8ce2 !important;
}

button.add-block-btn-new {
  position: relative;
  z-index: 9;
}

p.select-lyrics {
  color: #2092eb;
  font-size: 15px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin: 0;
  font-weight: 500;
  font-family: 'Roboto Condensed', sans-serif;
}
.inspiration {
  font-size: 12px;
}

.inspiration.isNot {
  padding-top: 5px;
  font-size: 13px;
}

.date-size-new {
  padding-left: 8px;
  text-align: left;
}

.Add-note.notebooks {
  background: transparent;
  padding: 0 0 0 9px;
  cursor: pointer;
}

.Add-note.notebooks.active {
  background: #fff;
  color: #1d89dc;
  padding: 1px 10px;
}
.Add-note.notebooks.active .add-icon {
  width: 17px;
}

.Add-note.notebooks.active span {
  color: #1d89dc;
  font-size: 12px;
}

.Add-note.notebooks.active svg path {
  stroke: #1d89dc;
}

.AI-generated.cursor.active svg path {
  stroke: #1f8ee5;
}

.AI-generated.cursor.active {
  background: #fff;
  margin: 5px 15px;
  border-radius: 8px;
  padding: 2px 0px 3px 9px;
}

.AI-generated.cursor.active span {
  font-size: 12px;
  color: #1f8ce2;
}

.AI-generated.cursor.active svg {
  width: 18px;
  height: auto;
}

.MuiMenuItem-root {
  font-size: 12px !important;
}

em {
  font-size: 12px;
}

.drag-btn-right-bar p b {
  font-size: 13px;
  font-weight: 500;
}
.custom-check-ai svg {
  /* fill: #1d87da; */
  font-size: 19px;
}
.custom-check-ai svg path {
  stroke: #e5e5e5 !important;
  /* font-size: 19px; */
}

/* .footer-custom.footer-active.is_not_title {
  width: calc(100vw - 560px);
} */

/* .print-page {
  height: 100vh !important;
} */

button.select-all-icon svg {
  margin-bottom: 2px;
}

.Theme-text span {
  color: red;
}

img.play-btn.pause-block.two {
  width: 22px;
}

.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-sizeSmall.language-select
  svg {
  position: absolute;
  right: 5px;
  background-color: #fff;
  color: #1f8ce2;
  /* font-size: 22px; */
}
.ai-assistant-main .Mui-expanded svg,
.ai-assistant-main .MuiAccordionSummary-expandIconWrapper svg {
  color: #1f8ce2;
  /* font-size: 22px; */
}

.form-select:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: none !important;
  box-shadow: unset !important;
}
.new-sel-menu svg {
  position: absolute;
  right: 5px;
  background-color: #fff;
  color: #1f8ce2;
  /* font-size: 22px; */
}

.MuiInputBase-input:focus {
  border-radius: 4px;
  border-color: #80bdff;
  box-shadow: unset !important;
}

.pre-chorus-wrapper .custom-check-ai p {
  position: relative;
  bottom: 0px;
  font-size: 13px;
  font-family: 'Roboto Condensed', sans-serif;
}

main.main-wrapper {
  position: relative;
  /* overflow-y: auto; */
  overflow-x: hidden !important;
}

.footer-custom {
  position: absolute;
  bottom: 25px;
  width: 100%;
  left: 0;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 1202;
}
.French-Dreams-text-new h5 {
  color: #2092eb;
  font-size: 16px;
  font-weight: 400;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.French-Dreams-text-new {
  padding-top: 6px;
}
.ai-assistant-main .Mui-expanded {
  margin: 0px !important;
}

.cuatom-tab-bar {
  padding-bottom: 5px;
  margin-top: 5px;
}

/* .ai-header-wrapper.note {
  margin-bottom: 0.5rem;
} */

.ai-header-wrapper.note {
  margin-bottom: 10px;
  margin-top: 4px;
  position: relative;
  height: 30px;
}
/* new css  */

.toolbar-new.both-sidebar-active {
  width: calc(100% - 855px) !important;
  margin-right: 355px;
}

.both-sidebar-active .main-tab-content-wrapper {
  width: calc(100% - 56px);
}

.MuiTabPanel-root.song-block-wrapper {
  width: calc(100% - 12px);
  padding-right: 0px !important;
}

.right-header-sidebar-active .main-tab-content-wrapper {
  width: calc(100% - 73px);
}

.MuiTabPanel-root.note-book-wrapper {
  width: 100%;
  padding-right: 0px !important;
}

.toolbar-new.right-header-sidebar-active {
  width: calc(100% - 367px) !important;
  margin-right: 358px !important;
}

/* RANGE SLIDER */
/* .range-slider {
  margin: 20px;
  appearance: none;
  outline: none;
  width: 150px;
  height: 3px;
  border-radius: 5px;
  background-color: #1f8ce2;
}

.range-slide::-webkit-slider-runnable-track {
  background-color: rgb(113, 18, 18);
}

.range-slider::-webkit-slider-thumb {
  background: #fafafa;
  appearance: none;
  box-shadow: 1px 2px 26px 1px #bdbdbd;
  width: 15px;
  height: 15px;
  border-radius: 50%;
} */

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation0.MuiAlert-root {
  padding: 0 10px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation0.MuiAlert-root
  .MuiAlert-icon {
  cursor: pointer;
}
.select-btn span.filters.all-song-sort {
  padding: 1px 2px 1px 1px !important;
}
span.filters.all-song-sort {
  padding: 1px 3px 1px 3px !important;
}
span.filters.all-song-sort svg {
  width: 16px;
  height: 16px;
}
span.filters {
  padding: 3px 5px 4px 4px !important;
  border: dashed 1px #1f8de4;
  margin-left: 1px;
}
.per-heading-new.ps-4 h5 {
  font-weight: 700;
}
span.filters svg {
  width: 17px;
  height: 17px;
}

.filtered-menu .active,
filtered-menu .not-active:hover {
  background: #6eb1ee !important;
  color: #fff;
}

.filtered-menu .not-active {
  background: #ffffff;
  /* color: #fff; */
}

.perview-background {
  background-color: #ffffff;
  height: 100vh;
}
.script-name.recording {
  position: relative;
  top: 5px;
  font-weight: 400;
}

.pre-heading {
  color: #1e8de3;
  font-size: 18px;
  text-transform: capitalize;
  padding: 3px 0 0 50px;
}
img.perview-icon-tool {
  width: 35px;
}
.perview-new-page {
  border-bottom: 1px solid #e0e0e0;
  margin: 0 0 15px 0;
}
.toolbar-btn.preview {
  width: 24px;
  height: auto;
  margin-bottom: 2px;
  color: #1e8de3;
}

.printable {
  display: flex;
  flex-direction: column;

  min-height: calc(100vh - 60px);
}

.per-heading-new {
  flex: 1;
  /* Add any other styles you need for the main content */
}

.footer {
  padding: 10px;
  margin-top: auto;
}

.copyright {
  margin: 0;
}
.perview-background {
  overflow-y: scroll;
}

.ai-options {
  position: relative;
}

.ai-options .MuiCircularProgress-root {
  left: 13px;
}

[aria-disabled='true'] {
  opacity: 0.5; /* For example, reduce opacity to indicate disabled state */
  pointer-events: none; /* Prevents mouse events on the element */
}

[aria-disabled='true']:hover::after {
  content: 'At least one active item selected [Notebook, Song block, AI assistant ] '; /* Tooltip content */
  position: absolute; /* Position the tooltip relative to the parent */
  top: 100%; /* Position the tooltip below the parent element */
  left: 50%; /* Position the tooltip horizontally centered */
  transform: translateX(-50%); /* Center the tooltip horizontally */
  padding: 4px 8px; /* Add padding to the tooltip */
  background-color: #333; /* Tooltip background color */
  color: #fff; /* Tooltip text color */
  border-radius: 4px; /* Add border radius to the tooltip */
  font-size: 12px; /* Tooltip text size */
  white-space: nowrap; /* Prevent line breaks in the tooltip */
  z-index: 1; /* Ensure the tooltip is above other elements */
}

.arrow_icon .btn-loader {
  left: 99%;
}

/* Referral UI */

.referral_container .content-mid {
  width: 100%;
  display: table;
  margin: 0 auto;
  /* height: 100vh; */
  padding: 35px 20px;
}
.back-arrow-page-ref {
  position: absolute;
  margin-top: 15px;
}

.referral-page-custom a {
  color: #333;
}
.arrow-right-refe img {
  cursor: pointer;
}

button.share-btn-ref {
  padding: 8px 30px;
  border: 0;
  background-color: #2092eb;
  color: #fff;
  border-radius: 5px;
}
.copy-link-text a,
.link {
  color: #2092eb;
  text-decoration: underline;
}

.red-terms-req {
  color: #333;
}

.referral-page-custom h4 {
  font-weight: 600;
}

.referral-page-custom p {
  font-size: 14px;
}

.referral-page-custom a {
  font-size: 13px;
}

.your-earning-sec {
  padding: 5px 0;
  border-bottom: 1px solid #bdbdbd;
}

.your-earning-sec h5 {
  font-size: 14px;
  font-weight: 500;
  padding: 5px 0 0 0;
  margin: 0;
}

.referral-mid h2 {
  font-size: 20px;
  margin: 0;
  padding: 0;
}

.referral_balance .content-mid {
  padding: 10px 20px;
  margin-top: 1rem;
}

.baln-page-custom {
  border-bottom: 1px solid #bdbdbd;
  padding: 5px 0;
}

.baln-page-custom h4 {
  font-size: 24px;
  font-weight: 500;
  margin: 0;
  padding: 5px 0px;
}

.baln-page-custom p {
  font-size: 14px;
}

.baln-page-custom h5 {
  font-size: 16px;
  font-weight: 500;
}
.ref_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 2rem;
}
.content_ref_wrapper {
  padding: 1rem;
}

.content_ref_wrapper h4 {
  font-size: 22px;
  font-weight: 600;
  margin-top: 2rem;
}

.content_ref_wrapper p.para {
  font-size: 15px;
}

.content_ref_wrapper button {
  margin-top: 2rem;
}

.ref_container button.submit_btn {
  color: #fff !important;
}

span.earn-token {
  color: #2092ec;
  padding-right: 5px;
  cursor: pointer;
}
span.right-line {
  border-right: 1px solid #000;
  position: relative;
  top: 3px;
  height: 22px;
  margin-right: 5px;
}

span.copy-link-text {
  color: #2092eb;
  text-align: center;
  text-decoration: underline;
  position: relative;
  top: 5px;
  left: 5px;
  cursor: pointer;
}

/* new-design-css */

/* Add these styles to your CSS */
.no-internet {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.no-internet-inner {
  text-align: center;
}

.no-internet p {
  margin-top: 20px;
  font-size: 18px;
}

/* Adjust the size of the SVG logo */
.feather.feather-wifi-off {
  width: 100%;
  max-width: 100px;
  height: auto;
}

.beat_amount {
  border-radius: 10px;
  width: 60px;
  height: 25px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  background-color: #f4fbff;
  border: 1px solid #e0eeff;
  position: relative;
  z-index: 999;
}

.beat_amount.active {
  background: #1c8be0;
  color: #fff;
}

span.beat-download-loader {
  padding-left: 4px;
  display: flex;
}

.beat-download-loader
  span.MuiCircularProgress-root.MuiCircularProgress-indeterminate.MuiCircularProgress-colorPrimary {
  width: 15px !important;
  height: 15px !important;
}

.MuiPopperUnstyled-root {
  position: relative;
  z-index: 9999;
}

.heading-input {
  border: none;
  outline: none;
  width: 100%;
}

/* Change focus color */

/* General styling for the range input */

/*START MEDIA QUERY  */

@media screen and (max-width: 2500px) {
  .backTrack_select {
    width: 6vw !important;
  }
  .editor_content {
    overflow: scroll;
    /* height: 70vh; */
  }
  .script-box h6 {
    /* width: 18vw; */
    width: 100%;
  }
  .song-block {
    width: 100%;
  }
  .editor_content {
    width: 100%;
  }
  .main {
    overflow: hidden;
  }
}

@media screen and (min-width: 1920px) and (max-width: 2100px) {
  ul.drag_idea_list {
    max-height: calc(68vh - 95px);
    overflow-y: scroll;
    height: 100%;
  }
}

@media screen and (min-width: 1920px) {
  .record-drum-sec {
    width: 300px;
  }

  .custom-table-right-bar.tableWrap {
    overflow-y: scroll;
    /* height: 65vh; */
    overflow-x: hidden;
    height: 100%;
    max-height: calc(79vh - 100px);
  }
}

@media screen and (max-width: 1920px) {
  .song-block-content {
    padding: 1rem;
    overflow-y: auto;
    min-height: 20vh;
    max-height: 25vh;
    /* width: 56vw; */
  }
  .editor_content {
    overflow: scroll;
    /* height: 65vh; */
    width: 100%;
  }
  .main {
    overflow: hidden;
  }
}

@media only screen and (max-width: 1500px) {
  .grid-box-sec {
    display: grid;
    grid-template-columns: 15% 77% auto;
  }

  .grid-box-sec-two {
    grid-template-columns: 15% 30% 9% 9% 29% 3%;
  }

  .grid-box-sec-three,
  .grid-box-sec-four {
    grid-template-columns: 15% 90%;
  }
}

@media screen and (max-width: 1440px) {
  .grid-box-sec {
    display: grid;
    grid-template-columns: 16% 75% auto;
  }

  .grid-box-sec-two {
    grid-template-columns: 16% 26% 13% 10% 26% 3%;
  }

  .custom-check-ai {
    font-size: 13px;
  }

  .grid-box-sec-three,
  .grid-box-sec-four {
    grid-template-columns: 16% 90%;
  }
  ul.bottom-tags-custom {
    max-height: 40vh !important;
  }
  .buttons-right-bar.sec {
    padding-bottom: 2rem;
    /* height: 40vh; */
  }
  ul.bottom-se.new-bottom {
    min-height: 16%;
    /* padding-bottom: 2rem; */
  }
  .buttons-right-bar.sec {
    padding-bottom: 2rem;
    /* height: 35vh !important; */
  }
  ul.bottom-tags-custom {
    max-height: 40vh !important;
  }

  .footer-bottom-in-active {
    width: 67%;
    margin: 0 auto;
  }

  .editor_content {
    width: 100%;
  }
  .rightToggle .gray-box-ai {
    padding: 10px 10px 10px 4px;
  }
}
@media screen and (max-width: 1400px) {
  .French-Dreams-text {
    width: 50%;
  }
  .balance-reobon {
    width: 65%;
    display: flex;
    justify-content: end;
  }
}

@media screen and (max-width: 1350px) {
  .script-box h6 {
    width: 23vw;
  }
  .footer-bottom-in-active {
    width: 67%;
    margin: 0 auto;
  }

  .play-bar,
  .volume-bar-input {
    width: 90px;
  }

  .drum-custom {
    padding-top: 2px;
    margin-left: 10px;
  }
}

/* Minimum height */

/* Maximum height */
@media (min-height: 1000px) {
  .drag_idea_list {
    overflow-y: auto;
    min-height: 100px;
    /* max-height: 58vh;
    height: 55rem; */
  }

  ul.bottom-se.new-bottom {
    /* padding-bottom: 2rem; */
    height: 26rem;
  }
}

@media (max-height: 575px) {
  .folder_wrapper {
    height: unset;
  }
}
