.profile_null_bg {
  height: 36px;
  background: #fafafa;
  width: 100%;
  border-bottom: 1px solid #dfdfdf;
}

.profile-section {
  display: flex;
  background-color: #fff;
}
.pro-content {
  margin-top: 1rem;
  margin-left: 0.6rem;
}
.pro-heading {
  font-size: 13px;
  text-decoration: none;
}
a.link,
a.link:hover {
  text-decoration: none;
  color: unset;
}

p.email {
  font-size: 0.8em;
  font-weight: 400;
}

.profile-bg-header {
  background-color: #fff !important;
  border-bottom: 1px solid #dfdfdf;
}

.pro-gray-shape.d-flex {
  padding: 10px 0 7px 10px;
  font-size: 16px;
  cursor: pointer;
}

p.app_version {
  font-size: 0.9em;
  font-weight: 300;
  color: #d3d3d3;
  font-family: sans-serif;
}

p.copyright {
  font-size: 0.8em;
  color: #000;
  font-weight: 400;
}

.delete-account {
  text-align: center;
  padding-top: 3rem;
}
.delete-account .pro-heading {
  color: #1f8ee5;
}

.arrow_icon {
  width: 40%;
  text-align: right;
  padding: 0 10px;
}

.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded {
  width: 60% !important;
}

.profile-bg-header {
  position: relative;
}

span.user_name {
  position: relative;
  font-size: 12px;
  bottom: 14px;
  color: #000000;
}
