.isMac .logo-header.text-left {
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}

.isMac h5.heading {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  text-align: center;
}

.isMac .download-app-icon svg {
  width: 100%;
  height: 200px;
  position: relative;
  margin-bottom: -120px;
  bottom: 50px;
}

@media screen and (min-width: 900px) {
  .isMac .padding-left-right-50 {
    margin-left: 80px !important;
  }
}
