* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: 'Roboto Condensed', sans-serif !important; */
}

.custom_loader {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
  position: absolute;
  background: #00000036;
  z-index: 9999;
  width: 100vw;
}

/* main.shiftRight {
  padding: 15px 0 0px 0px;
} */

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  width: 500px;
}

.ProseMirror:focus {
  outline: none;
}

.ProseMirror p.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: #b3b3b3;
  pointer-events: none;
  height: 0;
  /* font-family: 'roboto', arial, helvetica, sans-serif !important; */
  font-size: 12px;
  letter-spacing: 0.8px;
}
/* .rightDrawer {
  height: 20px;
} */
.custom_editor_toolbar {
  overflow: inherit !important;
}
/* .editor_content {
  overflow: scroll;
} */
.right-header-icons img {
  margin: 0 10px 0 10px;
}

.Generator-content.pt-3 p {
  color: #525252;
  font-size: 15px;
}
.Generator-content span {
  margin: 0 7px 0 7px;
  font-size: 15px;
}
span.Generator-tab.active {
  color: #2092eb;
  font-size: 15px;
}
img.edit-icon-right-bar-inner {
  position: absolute;
  right: 0;
  bottom: 5px;
}
.Generator-content {
  position: relative;
}
.buttons-right-bar ul li {
  font-size: 13px;
  display: inline-block;
  margin: 0 3px 5px;
  border: 1px solid #e0eeff;
  padding: 4px 0;
  background-color: #f4fbff;
  border-radius: 50px;
  color: #2092eb;
  width: 30%;
  text-align: center;
  font-weight: 500;
}

buttons-right-bar ul.chords {
  min-height: 17vh;
  max-height: 16vh;
}

.buttons-right-bar ul li.active {
  font-weight: 400;
  border: 1px solid #e0eeff !important;
  cursor: pointer;
  background-color: #2092eb;
  color: #fff;
  font-family: 'Roboto Condensed', sans-serif;
}
li.right-bar-btn-list.active {
  font-weight: 500;
  border: 1px solid #5384ab !important;
  cursor: pointer;
  font-family: 'Roboto Condensed', sans-serif;
}
li.right-bar-btn-list,
span.Generator-tab {
  cursor: pointer;
}
.drag-btn-right-bar ul {
  padding: 0;
}
.drag-btn-right-bar ul li {
  display: inline-block;
  margin: 0 5px 10px 5px;
  border: 1px solid #e5e5e5;
  padding: 0px 9px 1px 5px;
  background-color: #fff;
  border-radius: 50px;
  color: #5384ab;
  text-align: center;
}
li.drag-btn-inner svg {
  width: 20px;
  height: auto;
  margin-right: -6px;
}
.drag-btn-right-bar ul li a {
  text-decoration: none;
  color: #000000;
}
img.add-blue-btn-iiner {
  width: 20px;
  margin: 0 0 0 8px;
}

.MuiLoadingButton-loadingIndicator {
  color: #fff !important;
  z-index: 99999 !important;
  /* background: #ff5f00 !important; */
}

.icon_close_btn {
  position: absolute;
  right: 20px;
  top: 10px;
  cursor: pointer;
}

/* .select-text {
  margin-right: 10px;
} */

.song_des {
  --max-lines: 3;
  --line-height: 1.4;
  max-height: calc(var(--max-lines) * 1em * var(--line-height));
  line-height: var(--line-height);
}
.toast_ui div div {
  background-color: #1a5b8f !important;
}

div#__react-alert__ {
  z-index: 99999;
  position: absolute;
}

.ProseMirror > h6 {
  /* position: absolute; */
  /* line-height: 0; */
}

.ProseMirror > p {
  line-height: 2;
  margin-bottom: 0;
  font-size: 13px;
  font-weight: 300;
}
