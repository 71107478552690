body {
  font-family: 'Montserrat', sans-serif;
}

section.plan-new {
  width: 60%;
  margin: auto;
}
span.pe-2.depricate {
  text-decoration-line: line-through;
}
.tick-with-text p {
  font-size: 12px;
  margin-bottom: 8px;
}
.tick-with-text img {
  margin-right: 10px;
}
.Horizontal-plan-tab.inactive h6 {
  color: #1c1c4b;
  font-weight: 600;
  font-size: 14px;
}
.Horizontal-plan-tab.inactive p {
  font-size: 12px;
  color: #818181;
  margin: 0;
  font-weight: 500;
}
.Horizontal-plan-tab.inactive h5 {
  font-size: 14px;
  color: #2092eb;
  margin-bottom: 8px;
  font-weight: 700;
}

.Horizontal-plan-tab.inactive {
  border: 1px solid #eaecf0;
  border-radius: 10px;
  padding: 10px;
}
.Horizontal-plan-tab.active {
  border: 1px solid #eaecf0;
  border-radius: 10px;
  padding: 10px;
  background-image: linear-gradient(to right, #1c82d1, #2092eb);
}
.Horizontal-plan-tab.active h6 {
  color: #fff;
  font-weight: 600;
  font-size: 14px;
}
.Horizontal-plan-tab.active p {
  font-size: 13px;
  color: #fff;
  margin: 0;
  font-weight: 500;
}
.Horizontal-plan-tab.active h5 {
  font-size: 14px;
  color: #fff;
  margin-bottom: 8px;
  font-weight: 700;
}
button.Continue-Pro {
  background-image: linear-gradient(to right, #1c82d1, #2092eb);
  width: 100%;
  padding: 10px;
  color: #fff;
  border: 0;
  border-radius: 8px;
  margin-top: 10px;
}
.text-center.term-policy.mt-2 a {
  color: #2092eb;
  font-size: 12px;
  font-weight: 500;
}

.tab-div {
  background: #e5e5e5;
  border-radius: 10px;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.check-div .Mui-checked {
  color: #fff !important;
}
.plan-footer-text span {
  font-size: 12px;
}
span.dot-span span svg {
  background: #46b264 !important;
  border-radius: 50%;
  color: #46b264 !important;
  font-size: 0.8rem;
}
.popular {
  position: relative;
  right: 2px;
  top: 1px;
}

.plan-active {
  background: #2092eb;
  padding: 8px;
  border-radius: 8px;
  color: #fff;
  font-weight: 500;
}
.most-populor {
  position: absolute;
  background-color: #fff;
  border-radius: 17px;
  color: #47b263;
  margin: -5px 0 0 15px;
  padding: 0 7px 0 7px;
}
.most-populor span {
  font-size: 12px;
}

@media (max-width: 1024px) {
  section.plan-new {
    width: 100%;
    margin: auto;
  }
}

@media (max-width: 1000px) {
  .most-populor {
    position: absolute;
    background-color: #fff;
    border-radius: 17px;
    color: #47b263;
    padding: 0 5px 0 1px;
    margin: -5px 0px 0px -50px;
    height: 28px;
  }
  .most-populor span {
    font-size: 10px;
}
span.dot-span span svg {
  background: #46b264 !important;
  border-radius: 50%;
  color: #46b264 !important;
  font-size: 10px;
}
}
