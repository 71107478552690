.price-row {
  width: 100%;
  max-width: 1100px;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 25px;
}

.price-col {
  background: #1a5b8f;
  padding: 10% 15%;
  border-radius: 10px;
  color: #fff;
  text-align: center;
}

.price-col p {
  font-size: 22px;
}

.price-col h3 {
  font-size: 44px;
  margin: 20px 0 40px;
  font-weight: 500;
}

.price-col h3 span {
  font-size: 16px;
}

.price-col ul {
  text-align: left;
  margin: 20px 0;
  color: #ddd;
}

.price-col ul li {
  margin: 15px 0;
  size: 20px;
}

.price-col button {
  width: 100%;
  padding: 13px 0;
  background: transparent;
  color: #fff;
  font-size: 15px;
  border: 1px solid #fff;
  border-radius: 6px;
  cursor: pointer;
  transition: background 0.5s;
}

.price-col button:hover {
  background: #ffffff;
  color: #1a5b8f;
}
