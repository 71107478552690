.timer-wrapper {
  display: flex;
  justify-content: center;
}

.timer {
  font-family: 'Roboto Condensed', sans-serif;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.text {
  color: #aaaaaa;
  /* display: none; */
}
.value {
  font-size: 60px;
  color: #1a70b4;
  font-family: 'Roboto Condensed', sans-serif;
}

.timer.value {
  font-size: 60px;
  color: #1a70b4;
  font-family: 'Roboto Condensed', sans-serif;
  /* display: none; */
}

.timer-wrapper svg path {
  fill: #dfefffb8;
  stroke-width: 2;
}

.info {
  max-width: 360px;
  margin: 40px auto 0;
  text-align: center;
  font-size: 16px;
}
